import '@popperjs/core';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';
import Modal from 'bootstrap/js/src/modal';
import jQuery from 'jquery';

const faballous = {
  init() {
    const url = window.location.search;
    const params = new URLSearchParams(url);

    // If URL contains form sent param, do popup.
    if (params.has('formulierVerzonden') || params.has('formSent') || params.has('formularzWysłany')) {
      // Set up modal
      const modalElement = document.getElementById('formSentModal');
      const formSentModal = new Modal(modalElement);
      formSentModal.show();

      // Reroute
      modalElement.addEventListener('hidden.bs.modal', () => {
        window.location.href = window.location.href.split('?')[0].toString();
      });
    }
  },
};

document.addEventListener('DOMContentLoaded', () => {
  faballous.init();
});

function makeNewPosition() {
  // Get viewport dimensions (remove the dimension of the div)
  const h = jQuery(window).height() - 50;
  const w = jQuery(window).width() - 50;
  const nh = Math.floor(Math.random() * h);
  const nw = Math.floor(Math.random() * w);
  return [nh, nw];
}

function calcSpeed(prev, next) {
  const x = Math.abs(prev[1] - next[1]);
  const y = Math.abs(prev[0] - next[0]);
  const greatest = x > y ? x : y;
  const speedModifier = 0.2;
  const speed = Math.ceil(greatest / speedModifier);
  return speed;
}

function animateDiv() {
  const today = new Date();
  const isAprilFools = today.getDate() === 1 && today.getMonth() === 3;

  if (!isAprilFools) {
    jQuery('.parrot').hide();
  } else {
    const random = Math.round(Math.random());
    if (random === 0) {
      jQuery('.parrot-image').css('transform', 'rotateY(180deg)');
    } else {
      jQuery('.parrot-image').css('transform', 'rotateY(0deg)');
    }
    const newq = makeNewPosition();
    const oldq = jQuery('.parrot').offset();
    const speed = calcSpeed([oldq.top, oldq.left], newq);
    jQuery('.parrot').animate({ top: newq[0], left: newq[1] }, speed, () => {
      animateDiv();
    });
  }
}
